<template>
  <section class="how-to-work-block">
    <v-container class="mb-15">
      <p class="section-title section-title__how  white--text">How we work</p>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" md="4" class="d-flex pa-md-0 pa-10">
          <v-img
            class="d-block"
            width="500"
            height="500"
            src="@/assets/img/step.png"
          ></v-img>
        </v-col>
        <v-col cols="12" md="8" class="pa-md-0 pa-md-10 pa-2" style="z-index:2;">
          <div class="d-flex justify-center">
            <StepLine />

            <InfoList />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  components: {
    InfoList: () => import("./infoList.vue"),
    StepLine: () => import("./stepLine.vue"),
  },
  data: () => ({
    wheelHandler: null,
  }),
  computed: {},
  mounted() {
    // this.wheelHandler = this.debounce(this.callback)
    // this.$el.addEventListener("wheel", this.wheelHandler);
  },
  beforeDestroy() {
    // this.$el.removeEventListener("wheel", this.wheelHandler);
  },
  methods: {
    callback(e) {
      console.log(e);
      const { deltaY } = e;
      this.$root.$emit("infoWheelEvent", deltaY);
    },
    debounce(func, delay = 500) {
      let timer = null;
      return (e) => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, [e]);
        }, delay);
      };
    },
  },
};
</script>