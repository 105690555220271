<template>
  <section class="work-section">
    <v-container>
      <p class="section-title section-title__works white--text">Works</p>
    </v-container>
    <div>
      <div class="d-flex flex-wrap">
        <div
          v-for="(photo, i) in photos"
          :key="i"
          class="relative work-section__item"
        >
          <v-img
            class="work-section__item__photo work-section__item__photo__label"
            :data-label="photo.label"
            :src="photo.src"
          ></v-img>
          <v-btn class="view-btn" fab :to="photo.link">View</v-btn>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    photos: [
      { label: "ECV 2021 全球化官網與審核系統", src: require("@/assets/img/works/ECV.jpg") , link: {name: 'detail.ecv'}},
      { label: "HAKKA TV 2021 直播影音串流平台", src: require("@/assets/img/works/HakkeTV.jpg") , link: {name: 'detail.hakka'}},
    ],
  }),
};
</script>