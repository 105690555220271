<template>
  <section class="banner-section">
    <!-- <div class="banner-section__content d-flex justify-center align-center">
      <p class="banner-section__content__title white--text text-center">
        服務客戶超過 500+ <br> <span class="green--text">創意</span>的加速站，<span class="blue--text">技術</span>的鑽研者
      </p>
    </div> -->
    <div class="relative">
      <!-- <video
        width="100%"
        muted
        autoplay
        loop
        style="mix-blend-mode: luminosity"
      >
        <source src="@/assets/img/test.mp4" type="video/mp4" />
      </video> -->
      <v-img src="@/assets/img/banner.png" class="d-md-block d-none" width="100%" height="1000px" style="mix-blend-mode: luminosity"></v-img>
      <v-img src="@/assets/img/bannerM.png" contain class="d-md-none d-block" width="100%" style="mix-blend-mode: luminosity"></v-img>
      <p class="banner-section__content__title white--text text-center">
        服務客戶超過 500+ <br> <span class="green--text"> 創意 </span>的加速站，<span class="blue--text">技術 </span>的鑽研者
      </p>
      <p class="banner-section__content__subtitle">數位因子網路科技</p>
      <div class="banner-section__gradient text-center">
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>