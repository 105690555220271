<template>
  <section class="product-section mb-15">
    <v-container class="mb-15">
      <p class="section-title section-title__product white--text">經典案例</p>
    </v-container>
    <div>
      <div class="d-flex flex-wrap">
        <a
          v-for="(photo, i) in photos"
          :key="i"
          class="relative product-section__item"
          :class="{'product-section__item--active': activeIndex == i}"
          @mouseenter="handleMouseEnter(i)"
          @mouseleave="handleMouseLeave"
          :href="photo.href"
          target="_blank"
        >
          <v-img eager class="product-section__item__photo" :src="photo.src" :lazy-src="photo.src"></v-img>
          <div
            class="
              product-section__item__title
              d-flex
              justify-space-between
              align-end
              px-4
            "
          >
            <h1>{{ photo.label }}</h1>
            <div class="view-btn mr-md-3 mr-12 pa-5">
              <v-img max-width="85" min-width="40" :src="require('@/assets/img/icon/arrow.svg')"></v-img>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="mx-auto d-flex justify-center">
      <v-btn @click="goProjects" large rounded class="mt-10 mb-20">了解更多</v-btn>
    </div>
  </section>
</template>

<script>
export default {
  data: () => ({
    activeIndex: 0,
    photos: [
      { label: "影音平台", src: require("@/assets/img/p2.png"),href:"detail/hakka" },
      { label: "電商購物平台", src: require("@/assets/img/p1.png"),href:"https://www.designplus.style/" },
      { label: "IOT APP", src: require("@/assets/img/p3.png"),href:"https://www.unibless.com/" },
    ],
  }),
  methods: {
    handleMouseEnter(index) {
      this.activeIndex = index
    },
    handleMouseLeave() {
      this.activeIndex = -1
    },
    goProjects() {
      this.$router.push({name: 'projects'})
    },
  },
};
</script>