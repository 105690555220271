<template>
  <div class="home-page">
    <!-- Banner -->
    <Banner class="mb-10" />
    <!-- Service -->
    <Service />

    <Puzzle class="mt-200 mb-200" />

    <!-- Product -->
    <Product />
    <!-- Partner -->
    <Partner />
    <!-- How -->
    <How />
    <!-- Talk -->
    <Talk class="mb-4 mt-3" />
    <!-- footer -->
    <Footer />
  </div>
</template>

<script lang="babel" type="text/babel">
import Puzzle from "@/components/puzzleEasy.vue"
import Banner from "@/components/banner.vue"
import Slider from "@/components/slider/slider.vue"
import Service from "@/components/service.vue"
import Work from "@/components/work.vue"
import Partner from "@/components/partner.vue"
import Product from "@/components/product.vue"
import Tool from "@/components/tool.vue"
import Talk from "@/components/talk.vue"
import Footer from "@/components/footer.vue"
import How from "@/components/howToWork/howToWork.vue"
export default {
  components: {
    Banner,
    Slider,
    Service,
    Work,
    Partner,
    Product,
    Tool,
    Talk,
    Footer,
    How,
    Puzzle
  },
  data: () => ({
    srcs: [
      "https://placeimg.com/200/200/any?1",
      "https://placeimg.com/200/200/any?2",
      "https://placeimg.com/200/200/any?3",
      "https://placeimg.com/200/200/any?4",
    ],
  }),
  computed: {
    sliderOptions() {
      return {
        responsive: {

        },
        nav: false,
        dots: false,
        autoWidth: true,
      }
    },
  },
  watch: {

  },

  methods: {

  },
}
</script>

<style lang="sass" type="text/sass">
</style>