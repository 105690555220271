<template>
  <div class="owl">
    <carousel
      :key="refreshKey"
      v-bind="computedOptions"
      responsiveBaseElement="body"
      @resized="resized"
    >
      <slot name="items"></slot>
    </carousel>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel2";
export default {
  components: { carousel },
  props: {
    refreshKey: Number,
    options: Object,
    bottomWave: {
      type: String,
    },
    topWave: {
      type: String,
    },
  },
  data: () => ({
    defaultOption: {

    },
  }),
  computed: {
    computedOptions() {
      return {...this.defaultOption, ...this.options}
    },
  },
  methods: {
    resized() {
      this.$emit("resize");
    },
  },
};
</script>
